<template>
  <div>
    <div
      class="disease-name text-right"
      v-for="disease in cropDiseasesFiltered"
      :key="disease.id"
    >
      {{ $t(`diseases.${disease.code}`) }}
    </div>
  </div>
</template>

<script>
import CropDiseasesMixin from '@/components/Spraying/CropDiseasesMixin.vue'
export default {
  mixins: [CropDiseasesMixin],
}
</script>

<style scoped>
.disease-name {
  font-size: 12px;
  line-height: 15px;
}
</style>
